import { useState } from "react";
import { Container } from "react-bootstrap";
import TableView from "./TableView";

const Visualizer = ({lcas})=>{

    const [type,setType] = useState('table');

    return (
        <Container>
            <h1>{type=='table'?'Result':''}</h1>
            {
                lcas?
                type=='table'?
                <TableView lcas={lcas}></TableView>
                :
                <TableView lcas={lcas}></TableView>
                :
                <p>Result Empty</p>
            }
        </Container>
    );
}

export default Visualizer;