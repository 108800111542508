import { Table } from "react-bootstrap";

const TableView = ({lcas})=>{
    const winner = ()=> lcas.result.reduce((item,curr)=>{
        if(item==null)
            return curr;
        else{
            if(+curr.total_votes> +item.total_votes)
                return curr;
            else
                return item;
        }
    },null);
    const sortedResult = ()=>lcas.result.sort((a,b)=>{
        return parseInt(a.total_votes)>parseInt(b.total_votes)
        ?-1:1;
    });
    const resultMapped = ()=>{
        let lost = sortedResult()[1];
        console.log(lost);
        return sortedResult().map((item,index)=>{
            if(index==0){
                item.margin = parseInt(item.total_votes)-parseInt(lost.total_votes)
            }
            else 
                item.margin = '';
            return item;
        })
    }
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                <th>#</th>
                <th>Name</th>
                <th>Party</th>
                <th>EVM Votes</th>
                <th>Postal Votes</th>
                <th>Total Votes</th>
                <th>Margin</th>
                <th>Percent Votes</th>
                </tr>
            </thead>
            <tbody>
                {
                    resultMapped().map((item,index)=>
                        {
                            return (
                                <tr>
                                    <td>{index+1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.party}</td>
                                    <td>{item.evm_votes}</td>
                                    <td>{item.postal_votes}</td>
                                    <td>{item.total_votes}</td>
                                    <td>{item.margin}</td>
                                    <td>{item.percentage_of_votes}</td>
                                </tr>
                            )
                        }
                    )
                }
            </tbody>
        </Table>
    ); 
}

export default TableView;