import { useState } from "react";
import { Form, ListGroup } from "react-bootstrap"

const SideBar = ({lcas,onItemChange }) => {
    const [searchKey,setSearchKey] = useState('');
    const [active,setActive] = useState(1);
    const filterLcas = ()=>{
        return lcas.filter(item=>item.text.toLowerCase().indexOf(searchKey.toString().toLowerCase())!=-1)
    }
    const handleChange = (event)=>{
        setSearchKey(event.target.value);
    }
    const handleItemClick = (item)=>{
        setActive(item.value)
        onItemChange(item)
    }
    return (
        <ListGroup >
            <ListGroup.Item>
            <Form.Control type="email" placeholder="Type to search" value={searchKey} onChange={handleChange} />
            </ListGroup.Item>
            <ListGroup className="full-height">
            {filterLcas().map(item =>
                <ListGroup.Item action onClick={(event)=>handleItemClick(item)} key={item.value} active={active==item.value}>{item.value}. {item.text}</ListGroup.Item>
            )}
            </ListGroup>
            {/* <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
            <ListGroup.Item>Morbi leo risus</ListGroup.Item>
            <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
            <ListGroup.Item>Vestibulum at eros</ListGroup.Item> */}
        </ListGroup>
    )
}


export default SideBar;