import { useState } from "react";
import { Col, Row } from "react-bootstrap"
import SideBar from "../components/SideBar"
import Visualizer from "../components/Visualizer"

const Home = ({data})=>{
    const [selected,setSelected]=useState({value:1});
    function lcasList(items) {
      return items?items
        .map(item => {return {text:item.text,value:item.value}})
        .sort((a, b) => {
          if(+a.value < +b.value) {
            return -1;
          } else if(+a.value > +b.value) {
            return 1;
          } else {
            return 0;
          }
        }):[];
    }
    function selectedLcas(data){
      
      return data?
        data.find(item => item.value==selected.value):null
    }
    const handleItemChange = (item)=>{
        console.log('callback in app', item);
        setSelected(item)
    }
    return(
        <Row>
            <Col xs={4} >
              <SideBar onItemChange={handleItemChange} lcas={lcasList(data)}/>
            </Col>
            <Col xs={8}>
              <Visualizer lcas={selectedLcas(data)}></Visualizer>
            </Col>
          </Row>
    )
}

export default Home;