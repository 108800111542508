import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';

import './App.css';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import { Route, Switch } from 'react-router';



const App = () => {
  const [resultData,setResultData]=useState([]);

  const getData=()=>{
    fetch('2021_all_res.json',{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
    .then(function(response){
      return response.json();
    })
    .then(function(myJson) {
      setResultData(myJson)
    });
  }
  useEffect(()=>{
    getData()
  },[])

  return (
      <div>
      <NavBar/>
      <Container className="p-3">
        {/* <Jumbotron>
          <h1 className="header">Welcome To React-Bootstrap</h1>
        </Jumbotron> */}
        <Switch>
          <Route path='/' exact
            render={(props) => (
              <Home {...props} data={resultData} />
            )}/>
          <Route path='/all-parties' exact
            render={(props) => (
              <Home {...props} data={resultData} />
            )}/>
        </Switch>
      </Container>
      </div>
    );
  }

export default App;
